import axios from '../../../axios';
import { getselectedUser } from '../../actions/data-list/index';
import { toast } from 'react-toastify';
const initialState = {
	allpatient: [],
	data: [],
	params: null,
	allData: [],
	totalPages: 0,
	filteredData: [],
	totalRecords: 0,
	sortIndex: [],
	selectedUser: null,
	selectedPatient: null,
	zones:[],
};

const determinePopularity = (val) => {
	if (val >= 75) return { popValue: val, color: 'success' };
	else if (val < 75 && val >= 55) return { popValue: val, color: 'primary' };
	else if (val < 55 && val >= 35) return { popValue: val, color: 'warning' };
	else if (val < 35 && val >= 0) return { popValue: val, color: 'danger' };
	else return { popValue: 0, color: 'danger' };
};

const moveIndex = (arr, from, to) => {
	let el = arr[from];
	arr.splice(from, 1);
	arr.splice(to, 0, el);
};

const getIndex = (arr, arr2, arr3, params = {}) => {
	if (arr2.length > 0) {
		let startIndex = arr.findIndex((i) => i.id === arr2[0].id) + 1;
		let endIndex = arr.findIndex((i) => i.id === arr2[arr2.length - 1].id) + 1;
		let finalArr = [startIndex, endIndex];
		return (arr3 = finalArr);
	} else {
		let finalArr = [arr.length - parseInt(params.perPage), arr.length];
		return (arr3 = finalArr);
	}
};

const updateUserData = (newObj) => {
	axios
		.put(`/admin/user/${newObj._id}`, newObj, {
			headers: { Authorization: localStorage.getItem('kad-token') },
		})
		.then((response) => {
			console.log(response.data);
		})
		.catch((error) => {
			console.log(error);
		});
};

const updatePatientData = (newObj) => {
	axios
		.put(`/admin/patient/${newObj._id}`, newObj, {
			headers: { Authorization: localStorage.getItem('kad-token') },
		})
		.then((response) => {
			console.log(response.data);
		})
		.catch((error) => {
			console.log(error);
		});
};

const updateDoctorData = (newObj) => {
	axios
		.put(`/admin/doctor/${newObj._id}`, newObj, {
			headers: { Authorization: localStorage.getItem('kad-token') },
		})
		.then((response) => {
			console.log(response.data);
			 toast.success("Successfully updated Doctor data");
		})
		.catch((error) => {
			console.log(error);
			toast.error("Sorry can't update try again!")
		});
};

const updateNurseData = (newObj) => {
	console.log(newObj);
	axios
		.put(`/admin/nurse/${newObj._id}`, newObj, {
			headers: { Authorization: localStorage.getItem('kad-token') },
		})
		.then((response) => {
			console.log(response.data);
		})
		.catch((error) => {
			console.log(error);
		});
};

const updatePathlabData = (newObj) => {
	axios
		.put(`/admin/pathlab/${newObj._id}`, newObj, {
			headers: { Authorization: localStorage.getItem('kad-token') },
		})
		.then((response) => {
			console.log(response.data);
		})
		.catch((error) => {
			console.log(error);
		});
};

const updatePharmacyData = (newObj) => {
	axios
		.put(`/admin/pharmacy/${newObj._id}`, newObj, {
			headers: { Authorization: localStorage.getItem('kad-token') },
		})
		.then((response) => {
			console.log(response.data);
		})
		.catch((error) => {
			console.log(error);
		});
};



const deleteUserData = (obj) => {
	axios
		.delete(`/admin/user/${obj._id}`, {
			headers: { Authorization: localStorage.getItem('kad-token') },
		})
		.then((response) => {
			console.log(response);
		})
		.catch((error) => {
			console.log(error);
		});
};

const deleteDoctorData = (obj) => {
	axios
		.delete(`/admin/doctor/${obj._id}`, {
			headers: { Authorization: localStorage.getItem('kad-token') },
		})
		.then((response) => {
			console.log(response);
		})
		.catch((error) => {
			console.log(error);
		});
};

const deleteNurseData = (obj) => {
	axios
		.delete(`/admin/nurse/${obj._id}`, {
			headers: { Authorization: localStorage.getItem('kad-token') },
		})
		.then((response) => {
			console.log(response);
		})
		.catch((error) => {
			console.log(error);
		});
};

const deletePathlabData = (obj) => {
	axios
		.delete(`/admin/pathlab/${obj._id}`, {
			headers: { Authorization: localStorage.getItem('kad-token') },
		})
		.then((response) => {
			console.log(response);
		})
		.catch((error) => {
			console.log(error);
		});
};

const deletePharmacyData = (obj) => {
	axios
		.delete(`/admin/pharmacy/${obj._id}`, {
			headers: { Authorization: localStorage.getItem('kad-token') },
		})
		.then((response) => {
			console.log(response);
		})
		.catch((error) => {
			console.log(error);
		});
};

const deletePatientData = (obj) => {
	axios
		.delete(`/admin/patient/${obj._id}`, {
			headers: { Authorization: localStorage.getItem('kad-token') },
		})
		.then((response) => {
			console.log(response);
		})
		.catch((error) => {
			console.log(error);
		});
};

const DataListReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_DATA':
			return {
				...state,
				data: action.data,
				totalPages: action.totalPages,
				params: action.params,
				sortIndex: getIndex(state.allData, action.data, state.sortIndex, action.params),
			};
		case 'GET_ALL_DATA':
			return {
				...state,
				allData: action.data,
				totalRecords: action.data.length,
				sortIndex: getIndex(action.data, state.data, state.sortIndex),
			};
		case 'FILTER_DATA':
			let value = action.value;
			let filteredData = [];
			if (value.length) {
				filteredData = state.allData
					.filter((item) => {
						let startsWithCondition =
							item.name.toLowerCase().startsWith(value.toLowerCase()) ||
							item.category.toLowerCase().startsWith(value.toLowerCase()) ||
							item.price.toLowerCase().startsWith(value.toLowerCase()) ||
							item.order_status.toLowerCase().startsWith(value.toLowerCase());

						let includesCondition =
							item.name.toLowerCase().includes(value.toLowerCase()) ||
							item.category.toLowerCase().includes(value.toLowerCase()) ||
							item.price.toLowerCase().includes(value.toLowerCase()) ||
							item.order_status.toLowerCase().includes(value.toLowerCase());

						if (startsWithCondition) {
							return startsWithCondition;
						} else if (!startsWithCondition && includesCondition) {
							return includesCondition;
						} else return null;
					})
					.slice(state.params.page - 1, state.params.perPage);
				return { ...state, filteredData };
			} else {
				filteredData = state.data;
				return { ...state, filteredData };
			}
		case 'ADD_DATA':
			let id = state.data.slice(-1)[0].id + 1;
			state.data.push({
				...action.obj,
				id,
				popularity: determinePopularity(action.obj.popularity),
			});
			moveIndex(
				state.data,
				state.data.findIndex((item) => item.id === id),
				0
			);
			return {
				...state,
				data: state.data,
				totalRecords: state.allData.length,
				sortIndex: getIndex(state.allData, state.data, state.sortIndex),
			};
		case 'UPDATE_USER_DATA':
			const newData = state.data.map((anItem) => {
				if (anItem._id === action.obj._id) return action.obj;
				return anItem;
			});
			console.log(action.obj);
			if (state.selectedUser.userType === 'User') updateUserData(action.obj);
			else if (state.selectedUser.userType === 'Doctor') updateDoctorData(action.obj);
			else if (state.selectedUser.userType === 'Nurse') updateNurseData(action.obj);
			else if (state.selectedUser.userType === 'Pathlab') updatePathlabData(action.obj);
			else if (state.selectedUser.userType === 'Pharmacy') updatePharmacyData(action.obj);
			else if (state.selectedUser.userType === "Patient") updatePatientData(action.obj);
			return { ...state, data: newData };
		case 'UPDATE_PATIENT_DATA':
			const newDataPatient = state.allpatient.map((anItem) => {
				if (anItem._id === action.obj._id) return action.obj;
				return anItem;
			});
			updatePatientData(action.obj);
			return {
				...state,
				data: newDataPatient,
			};
		case 'DELETE_USER_DATA':
			const newDataAfterDeletion = state.data.filter(
				(anItem) => anItem._id !== action.obj._id
			);
			
			 if (action.obj.userType === 'User') deleteUserData(action.obj);
			
			else if (action.obj.userType=== 'Doctor') deleteDoctorData(action.obj);
			else if (action.obj.userType === 'Nurse') deleteNurseData(action.obj);
			else if (action.obj.userType=== 'Pathlab') deletePathlabData(action.obj);
			else if (action.obj.userType=== 'Pharmacy') deletePharmacyData(action.obj);
			return {
				...state,
				data: newDataAfterDeletion,
			};
		case 'DELETE_PATIENT_DATA':
			const newDataAfterDeletionPatient = state.allpatient.filter(
				(anItem) => anItem._id !== action.obj._id
			);
			console.log(newDataAfterDeletionPatient);
			deletePatientData(action.obj);
			return {
				...state,
				allpatient: newDataAfterDeletionPatient,
			};
		case 'SELECT_USER':
			return {
				...state,
				selectedUser: {
					...action.selectedUser,
					userType: action.userType,
				},
			};
		case 'SELECT_PATIENT':
			return {
				...state,
				selectedPatient: action.selectedPatient,
			};
		case 'GET_ALLPATIENTS':
			return {
				...state,

				allpatient: action.allpatient,
			};
		case 'GET_ALLZONES':
			return {
				...state,
				zones:action.data,
			}
		default:
			return state;
	}
};

export default DataListReducer;
