import axios from '../../../axios';
import { ToastContainer, toast } from "react-toastify";
//parent zones
export const addParentZone=(name) => {
	console.log(name);
	return async (dispatch) => {
		await axios
			.post(`admin/parentZone`,
			{
				"name": name,
			},
			{
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				toast.success("Successfully added ParentZone");
			}).catch((error)=>{
				toast.error("Sorry can't add try again!")
			});
	};
};

export const getallParentZone=() => {
	return async (dispatch) => {
		await axios
			.get(`admin/parentZones`,
			
			{
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				dispatch({
					type: 'GET_ALLZONES',
					data: response.data,
					// totalPages: response.data.totalPages,
					// params,
				});
				
			});
	};
};

export const editParentZone=(id,name,disabled) => {
	return async (dispatch) => {
		await axios
			.put(`admin/parentZone/${id}`,
			{
				name,
				disabled,
			},
			
			{
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				
				
			});
	};
};


export const deleteParentZone=(id) => {
	return async (dispatch) => {
		await axios
			.delete(`admin/parentZone/${id}`,
			
			{
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				
				
			});
	};
};

export const getzonesinParentZone=(id) => {
	return async (dispatch) => {
		await axios
			.get(`admin/parentZone/${id}/zones`,
			
			{
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				dispatch({
					type: 'GET_ALL_ZONES_IN_PARENTZONES',
					data: response.data,
					// totalPages: response.data.totalPages,
					// params,
				});
				
				
			});
	};
};

export const addDoctorParentZone=(id,parentZoneId)=>{
	return async (dispatch) => {
		
		await axios
			.patch(`admin/doctor/${id}/parentZone`, 
			{
				parentZoneId
			},
			{
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				
				
			});
	};
}

export const addNurseParentZone=(id,parentZoneId)=>{
	return async (dispatch) => {
		
		await axios
			.patch(`admin/nurse/${id}/parentZone`, 
			{
				parentZoneId
			},
			{
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				
				
			});
	};
}
export const addPathlabParentZone=(id,parentZoneId)=>{
	return async (dispatch) => {
		
		await axios
			.patch(`admin/pathlab/${id}/parentZone`, 
			{
				parentZoneId
			},
			{
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				
				
			});
	};
}
export const addPharmacyParentZone=(id,parentZoneId)=>{
	return async (dispatch) => {
		
		await axios
			.patch(`admin/pharmacy/${id}/parentZone`, 
			{
				parentZoneId
			},
			{
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				
				
			});
	};
}

//zones
export const getZonesData=(params) => {
	return async (dispatch) => {
		await axios
			.get(`admin/zonelist`, {
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				dispatch({
					type: 'GET_ALLZONES',
					data: response.data,
					// totalPages: response.data.totalPages,
					// params,
				});
			});
	};
};

export const delZoneData=(id) => {
	return async (dispatch) => {
		
		await axios
			.delete(`admin/zone/${id}`, {
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				toast.success("Succesfully deleted zone")
				
			}).catch((err)=>{
				toast.error("Sorry can't delete zone try again!")
			});
	};
};


export const addDoctorZone=(id,zones)=>{
	return async (dispatch) => {
		
		await axios
			.patch(`admin/doctor/${id}/zones`, 
			{
				zones
			},
			{
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				
				
			});
	};
}
export const disZone=(id)=>{
	return async (dispatch) => {
		
		await axios
			.patch(`admin/zone/${id}/toggledisable`,
			{

			},
			{
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				toast.success("Changes succesfully done");
				
				
			})
			.catch((err)=>{
				console.log(err.response);
				toast.error("Sorry can't perform changes!");
			});
	};
}
export const addNurseZone=(id,zones)=>{
	return async (dispatch) => {
		
		await axios
			.patch(`admin/nurse/${id}/zones`, 
			{
				zones
			},
			{
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				
				
			});
	};
}
export const addPathlabZone=(id,zones)=>{
	return async (dispatch) => {
		
		await axios
			.patch(`admin/pathlab/${id}/zones`, 
			{
				zones
			},
			{
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				
				
			});
	};
}
export const addPharmacyZone=(id,zones)=>{
	return async (dispatch) => {
		
		await axios
			.patch(`admin/pharmacy/${id}/zones`, 
			{
				zones
			},
			{
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				
				
			});
	};
}


//health packages
export const addHealthPackage=(name) => {
	console.log(name);
	return async (dispatch) => {
		await axios
			.post(`admin/healthpackage`,
			{
				"name": name,
			},
			{
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then(async(response) => {
				console.log(response);
				await toast.success("Successfully added HealthPackage");
			}).catch((error)=>{
				toast.error("Sorry can't add try again!")
			});
	};
};


export const editHealthPackage=async(name,id) => {
	console.log(name,id);
	
		await axios.put(`admin/healthpackage/${id}`,
			{
				name,
			},
			{
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then(async(response) => {
				console.log(response);
				await toast.success("Successfully edited HealthPackage");
			}).catch((error)=>{
				console.log(error);
				toast.error("Sorry can't edit try again!")
			});
	
};

export const deleteHealthPackage=(id) => {
	console.log(id)
	return async (dispatch) => {
		await axios
			.delete(`admin/healthpackage/${id}`,
			
			{
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then(async(response) => {
				console.log(response);
				await toast.success("Successfully deleted HealthPackage");
			}).catch((error)=>{
				toast.error("Sorry can't delete try again!")
			});
	};
};

export const getHealthPackages=() => {

	return async (dispatch) => {
		await axios
			.get(`public/healthpackage`
			
			)
			.then((response) => {
				console.log(response);
				dispatch({
					type: 'GET_ALLZONES',
					data: response.data,
					// totalPages: response.data.totalPages,
					// params,
				});
				//toast.success("Successfully deleted HealthPackage");
			}).catch((error)=>{
				toast.error("Sorry can't get healthpackages!")
			});
	};
};


//service query



export const getAllServiceQueries=() => {

	return async (dispatch) => {
		await axios
			.get(`admin/servicequeries`,
			
			{
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				dispatch({
					type: 'GET_DATA',
					data: response.data,
					// totalPages: response.data.totalPages,
					// params,
				});
				//toast.success("Successfully deleted HealthPackage");
			}).catch((error)=>{
				toast.error("Sorry can't get service queries!")
			});
	};
};


export const resolvequery=(id) => {
	console.log(id);
	return async (dispatch) => {
		await axios
			.patch(`admin/servicequeries/${id}/toggleresolve`,{},
			
			{
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then(async(response) => {
				
				console.log(response);
				if(response.data.success){
					await toast.success("Successfully resolved query");
				}
				
				// dispatch({
				// 	type: 'GET_DATA',
				// 	data: response.data,
				// 	// totalPages: response.data.totalPages,
				// 	// params,
				// });
				
			}).catch((error)=>{
				toast.error("Sorry can't resolve service queries!")
			});
	};
};



export const getHealthPackageOrders=() => {

	return async (dispatch) => {
		await axios
			.get(`admin/healthpackageorders`,
			
			{
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				dispatch({
					type: 'GET_DATA',
					data: response.data,
					// totalPages: response.data.totalPages,
					// params,
				});
				//toast.success("Successfully deleted HealthPackage");
			}).catch((error)=>{
				toast.error("Sorry can't get healthpackage orders!")
			});
	};
};

export const getHealthPackageOrdersById=(id) => {

	return async (dispatch) => {
		await axios
			.get(`admin/healthpackageorders/${id}`,
			
			{
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				dispatch({
					type: 'GET_DATA',
					data: response.data.data,
					// totalPages: response.data.totalPages,
					// params,
				});
				//toast.success("Successfully deleted HealthPackage");
			}).catch((error)=>{
				toast.error("Sorry can't get healthpackage orders!")
			});
	};
};


//change service providers for appointment


export const changeDoctor = (id) => {
	return async (dispatch) => {
		await axios
			.patch(`/admin/appointment/${id}/change/doctor`, {
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				dispatch({
					type: 'GET_DATA',
					data: response.data.data,
					// totalPages: response.data.totalPages,
					// params,
				});
			});
	};
};



export const changeNurse = (aptid,id) => {
	console.log(aptid,id);
	return async (dispatch) => {
		await axios
			.patch(`/admin/appointment/${aptid}/change/nurse`,
			{
				"newNurse" : id
			},
			{
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				// dispatch({
				// 	type: 'GET_DATA',
				// 	data: response.data.data,
				// 	// totalPages: response.data.totalPages,
				// 	// params,
				// });
			});
	};
};


export const changePathlab= (aptid,id) => {
	console.log(aptid,id);
	return async (dispatch) => {
		await axios
			.patch(`/admin/appointment/${aptid}/change/pathlab`,
			{
				"newPathlab" : id
			},
			{
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				// dispatch({
				// 	type: 'GET_DATA',
				// 	data: response.data.data,
				// 	// totalPages: response.data.totalPages,
				// 	// params,
				// });
			});
	};
};


export const changePharmacy= (aptid,id) => {
	console.log(aptid,id);
	return async (dispatch) => {
		await axios
			.patch(`/admin/appointment/${aptid}/change/pharmacy`,
			{
				"newPharmacy" : id
			},
			{
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				// dispatch({
				// 	type: 'GET_DATA',
				// 	data: response.data.data,
				// 	// totalPages: response.data.totalPages,
				// 	// params,
				// });
			});
	};
};





export const getUserData = (params) => {
	return async (dispatch) => {
		await axios
			.get(`/admin/userslist`, {
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				dispatch({
					type: 'GET_DATA',
					data: response.data.data,
					// totalPages: response.data.totalPages,
					// params,
				});
			});
	};
};


export const getglobalpatient=()=>{
	return async (dispatch) => {
		await axios
			.get(`admin/patientslist`, {
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log('full patlist', response);
				dispatch({
					type: 'GET_DATA',
					data: response.data.patients,
					// totalPages: response.data.totalPages,
					// params,
				});
			});
	};
}


export const getAllPatient = (id) => {
	return async (dispatch) => {
		await axios
			.get(`/admin/user/${id}/patients`, {
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log('all pat', response);
				dispatch({
					type: 'GET_ALLPATIENTS',
					allpatient: response.data.patients,
					// totalPages: response.data.totalPages,
					// params,
				});
			});
	};
};

export const getselectedUser = (user, userType) => {
	console.log(user);
	return (dispatch) => {
		dispatch({
			type: 'SELECT_USER',
			selectedUser: user,
			userType,
		});
	};
};

export const getSelectedPatient = (patient) => {
	return (dispatch) => {
		dispatch({
			type: 'SELECT_PATIENT',
			selectedPatient: patient,
		});
	};
};

export const deleteSelectedUser = (user) => {
	return (dispatch) => {
		dispatch({
			type: 'DELETE_USER_DATA',
			obj: user,
		});
	};
};

export const deleteSelectedPatient = (patient) => {
	return (dispatch) => {
		dispatch({
			type: 'DELETE_PATIENT_DATA',
			obj: patient,
		});
	};
};

export const getDoctorData = (params) => {
	return async (dispatch) => {
		await axios
			.get(`/admin/doctorslist`, {
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				dispatch({
					type: 'GET_DATA',
					data: response.data.data,
					// totalPages: response.data.totalPages,
					// params,
				});
			});
	};
};

export const verifyDoctor = (id) => {
	return async (dispatch) => {
		await axios.patch(
			`/admin/doctor/${id}/verify`,
			{},
			{
				headers: {
					Authorization: localStorage.getItem('kad-token'),
				},
			}
		);
		await axios
			.get(`/admin/doctorslist`, {
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				dispatch({
					type: 'GET_DATA',
					data: response.data.data,
					// totalPages: response.data.totalPages,
					// params,
				});
			});
	};
};

export const updateDoctorAvailability = (id) => {
	return async (dispatch) => {
		await axios.patch(
			`/admin/doctor/${id}/availablehome`,
			{},
			{
				headers: {
					Authorization: localStorage.getItem('kad-token'),
				},
			}
		);
		await axios
			.get(`/admin/doctorslist`, {
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				dispatch({
					type: 'GET_DATA',
					data: response.data.data,
					// totalPages: response.data.totalPages,
					// params,
				});
			});
	};
};

export const getNurseData = (params) => {
	return async (dispatch) => {
		await axios
			.get(`/admin/nurseslist`, {
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				dispatch({
					type: 'GET_DATA',
					data: response.data.data,
					// totalPages: response.data.totalPages,
					// params,
				});
			});
	};
};

export const verifyNurse = (id) => {
	return async (dispatch) => {
		await axios.patch(
			`/admin/nurse/${id}/verify`,
			{},
			{
				headers: {
					Authorization: localStorage.getItem('kad-token'),
				},
			}
		);
		await axios
			.get(`/admin/nurseslist`, {
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				dispatch({
					type: 'GET_DATA',
					data: response.data.data,
					// totalPages: response.data.totalPages,
					// params,
				});
			});
	};
};

export const getPathlabData = (params) => {
	return async (dispatch) => {
		await axios
			.get(`/admin/pathlabslist`, {
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				dispatch({
					type: 'GET_DATA',
					data: response.data.data,
					// totalPages: response.data.totalPages,
					// params,
				});
			});
	};
};

export const verifyPathlab = (id) => {
	return async (dispatch) => {
		await axios.patch(
			`/admin/pathlab/${id}/verify`,
			{},
			{
				headers: {
					Authorization: localStorage.getItem('kad-token'),
				},
			}
		);
		await axios
			.get(`/admin/pathlabslist`, {
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				dispatch({
					type: 'GET_DATA',
					data: response.data.data,
					// totalPages: response.data.totalPages,
					// params,
				});
			});
	};
};

export const getPharmacyData = (params) => {
	return async (dispatch) => {
		await axios
			.get(`/admin/pharmacylist`, {
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				dispatch({
					type: 'GET_DATA',
					data: response.data.data,
					// totalPages: response.data.totalPages,
					// params,
				});
			});
	};
};

export const verifyPharmacy = (id) => {
	return async (dispatch) => {
		await axios.patch(
			`/admin/pharmacy/${id}/verify`,
			{},
			{
				headers: {
					Authorization: localStorage.getItem('kad-token'),
				},
			}
		);
		await axios
			.get(`/admin/pharmacylist`, {
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				dispatch({
					type: 'GET_DATA',
					data: response.data.data,
					// totalPages: response.data.totalPages,
					// params,
				});
			});
	};
};


export const getPathlabOrders = (params) => {
	return async (dispatch) => {
		await axios
			.get(`/admin/orders/pathlab`, {
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response,"pathlab");
				dispatch({
					type: 'GET_DATA',
					data: response.data,
					// totalPages: response.data.totalPages,
					// params,
				});
			});
	};
};

export const getPharmacyOrders = (params) => {
	return async (dispatch) => {
		await axios
			.get(`/admin/orders/pharmacy`, {
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response,"pharmacy");
				dispatch({
					type: 'GET_DATA',
					data: response.data,
					// totalPages: response.data.totalPages,
					// params,
				});
			});
	};
};

export const getAppointmentData = (params) => {
	return async (dispatch) => {
		await axios
			.get(`/admin/appointments`, {
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				dispatch({
					type: 'GET_DATA',
					data: response.data.data,
					// totalPages: response.data.totalPages,
					// params,
				});
			});
	};
};

export const getOrderPayment = (params) => {
	return async (dispatch) => {
		await axios
			.get(`/admin/payments/orders`, {
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				dispatch({
					type: 'GET_DATA',
					data: response.data.data,
					// totalPages: response.data.totalPages,
					// params,
				});
			});
	};
};



export const getPaymentData = (params) => {
	return async (dispatch) => {
		await axios
			.get(`/admin/payments`, {
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				dispatch({
					type: 'GET_DATA',
					data: response.data.data,
					// totalPages: response.data.totalPages,
					// params,
				});
			});
	};
};

export const getSalesData = (params) => {
	return async (dispatch) => {
		console.log(params);
		await axios
			.get(`/admin/sales?filter=${params}`, {
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				dispatch({
					type: 'GET_DATA',
					data: response.data.data,
					// totalPages: response.data.totalPages,
					// params,
				});
			});
	};
};


export const getDoctorByCategory = (id) => {
	return async (dispatch) => {
		await axios
			.get(`/admin/doctorcategory/${id}/doctors`, {
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response+response.data.length);
				dispatch({
					type: 'GET_DATA',
					data: response.data,
					// totalPages: response.data.totalPages,
					// params,
				});
			});
	};
};

export const getDoctorCategoriesData = (params) => {
	return async (dispatch) => {
		await axios
			.get(`/admin/doctorcategories`, {
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				dispatch({
					type: 'GET_DATA',
					data: response.data,
					// totalPages: response.data.totalPages,
					// params,
				});
			});
	};
};

export const getPricesData = (params) => {
	return async (dispatch) => {
		await axios
			.get(`/admin/prices`, {
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				dispatch({
					type: 'GET_DATA',
					data: response.data,
					// totalPages: response.data.totalPages,
					// params,
				});
			});
	};
};

export const verifyPrice = (id) => {
	return async (dispatch) => {
		await axios.patch(
			`/admin/prices/verify/${id}`,
			{},
			{
				headers: {
					Authorization: localStorage.getItem('kad-token'),
				},
			}
		);
		await axios
			.get(`/admin/prices`, {
				headers: { Authorization: localStorage.getItem('kad-token') },
			})
			.then((response) => {
				console.log(response);
				dispatch({
					type: 'GET_DATA',
					data: response.data,
					// totalPages: response.data.totalPages,
					// params,
				});
			});
	};
};

export const getInitialData = () => {
	return async (dispatch) => {
		await axios.get('/api/datalist/initial-data').then((response) => {
			dispatch({ type: 'GET_ALL_DATA', data: response.data });
		});
	};
};

export const filterData = (value) => {
	return (dispatch) => dispatch({ type: 'FILTER_DATA', value });
};

export const deleteData = (obj) => {
	return (dispatch) => {
		axios
			.post('/api/datalist/delete-data', {
				obj,
			})
			.then((response) => {
				dispatch({ type: 'DELETE_DATA', obj });
			});
	};
};

export const updateData = (obj) => {
	return (dispatch, getState) => {
		axios
			.post('/api/datalist/update-data', {
				obj,
			})
			.then((response) => {
				dispatch({ type: 'UPDATE_DATA', obj });
			});
	};
};

export const updatepatient=(obj,id)=>{
	return async (dispatch) => {
		await axios.patch(
			`/admin/patient/${id}`,
			obj,
			{
				headers: {
					Authorization: localStorage.getItem('kad-token'),
				},
			}
		);
	}
}

export const addData = (obj) => {
	return (dispatch, getState) => {
		let params = getState().dataList.params;
		axios
			.post('/api/datalist/add-data', {
				obj,
			})
			.then((response) => {
				dispatch({ type: 'ADD_DATA', obj });
				dispatch(getUserData(params));
			});
	};
};
