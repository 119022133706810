import axios from "axios";
import environment from "./environment";

const axiosInstance = axios.create({
  baseURL:
    environment.NODE_ENV == "production"
      ? "https://api.knockadoc.com"
      : "https://api.knock-a-doc.dezignoo.com",
});

export default axiosInstance;
